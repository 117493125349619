$fontstack: 'Poppins', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #2589b1;
$colorblue: #2a2b2d;
$colororange: #2DA8D8;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}